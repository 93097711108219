<template>
  <!-- 调拨单管理 -->
  <div class="app-container">

    <el-form ref="form" :model="form" label-width="130px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="调拨单号">
              <el-input
                v-model="form.transferOrderCodeList"
                placeholder="多个以换行符分隔"
                type="textarea"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="关联单号">
              <el-input
                v-model="form.relationCodeList"
                placeholder="多个以换行符分隔"
                type="textarea"
                autosize
                resize="none"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="单据类型">
              <el-select v-model="form.orderTypeDictList" multiple collapse-tags :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_TRANSFER_ORDER_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="调拨类型">
              <el-select v-model="form.transferTypeDictList" :placeholder="$t('page.selectPlaceholder')" collapse-tags multiple filterable clearable>
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_TRANSFER_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-select v-model="form.stateDictList" :placeholder="$t('page.selectPlaceholder')" collapse-tags multiple filterable clearable>
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_TRANSFER_STATE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">

            <el-form-item label="Style">
              <Select
                v-model="form.styleIdList"
                api-key="styleList"
                multiple
                clearable
                :configuration="{ key: 'id', label: 'styleName', value: 'id' }"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="调出仓库">
              <Select
                v-model="form.outWarehouseCodeList"
                :select-options="warehouseOption"
                :configuration="optionsWarehouseConfig"
                clearable
                multiple
                collapse-tags
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="调入仓库">
              <Select
                v-model="form.inWarehouseCodeList"
                :select-options="warehouseOption"
                :configuration="optionsWarehouseConfig"
                multiple
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="调出备货渠道">
              <Select
                v-model="form.outStockChannelIdList"
                api-key="StockChannelList"
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="调入备货渠道">
              <Select
                v-model="form.inStockChannelIdList"
                :select-options="StockChannelOptions"
                :configuration="{ key: 'channelId', label: 'channelName', value: 'channelId' }"
                clearable
                multiple
                filterable
                collapse-tags
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期望上架日期" :class="$i18n.locale">
              <el-date-picker
                v-model="form.respectTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100%;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="创建人" :class="$i18n.locale">
              <el-select v-model="form.createByIdList" clearable filterable multiple collapse-tags style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.creatTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100%;"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="loading" @click="queryClick(1)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <el-button v-if="showBtn('add')" v-permission="'add'" class="mr-3" icon="el-icon-plus" type="primary" @click="handleAdd">{{ $t('page.create') }}</el-button>
        <el-button v-if="showBtn('batchImport')" v-permission="'batchImport'" class="mr-3" type="primary" @click="handleBatchImport">批量导入</el-button>
        <el-button v-if="showBtn('toWarehouse')" v-permission="'toWarehouse'" class="mr-3" type="primary" @click="ToWarehouse()">转仓库作业</el-button>
        <el-button v-if="showBtn('shortageTrans')" v-permission="'shortageTrans'" class="mr-3" type="primary" @click="handleUllage()">缺量调拨</el-button>
        <el-button v-if="showBtn('revokeTrans')" v-permission="'revokeTrans'" class="mr-3" type="danger" @click="handleCancel()">撤销调拨</el-button>
        <el-button v-if="showBtn('exportAll')" v-permission="'exportAll'" class="mr-3" type="primary" :loading="exportLoading" @click="exportConfirm">导出</el-button>
        <el-button v-if="showBtn('exportDetails')" v-permission="'exportDetails'" class="mr-3" type="primary" :loading="exportDetailsLoading" @click="exportDetails">导出明细</el-button>
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="tableLoading"
      :summary-method="getSummaries"
      show-summary
      border
      max-height="550"
      :header-cell-style="{background:'#fafafa'}"
      class="mb-3"
      :data="tableDatas"
      show-overflow-tooltip
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" />
      <el-table-column :label="$t('page.No')" type="index" />
      <el-table-column prop="transferOrderCode" label="调拨单号" width="200" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDetail(scope.row.transferOrderCode)">{{ scope.row.transferOrderCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="relationCode" align="center" label="关联单号" width="150" show-overflow-tooltip />
      <el-table-column prop="orderTypeI18" align="center" label="单据类型" />
      <el-table-column prop="transferTypeI18" align="center" label="调拨类型" />
      <el-table-column prop="stateI18" align="center" label="状态" show-overflow-tooltip />
      <el-table-column prop="styleName" align="center" label="Style" />
      <el-table-column prop="outStockChannelName" align="center" label="调出备货渠道" show-overflow-tooltip />
      <el-table-column prop="inStockChannelName" align="center" label="调入备货渠道" show-overflow-tooltip />
      <el-table-column prop="outWarehouseName" align="center" label="调出仓库" show-overflow-tooltip />
      <el-table-column prop="inWarehouseName" align="center" label="调入仓库" show-overflow-tooltip />
      <el-table-column prop="expectShelfDate" align="center" label="期待上架日期" show-overflow-tooltip />
      <el-table-column prop="expectTransferNumber" align="center" label="预计调拨数量" />
      <el-table-column prop="transferWarehouseNumber" align="center" label="已转仓库作业数量" />
      <el-table-column prop="notOutLockQty" align="center" label="未发锁定数量" />
      <el-table-column prop="shortageNumber" align="center" label="缺量数量" />
      <el-table-column prop="outWarehouseNumber" align="center" label="已出库数量" />
      <el-table-column prop="shelfNumber" align="center" label="已上架数量" />
      <el-table-column prop="exceptionRecord" align="center" label="异常记录" width="180" show-overflow-tooltip />
      <el-table-column prop="createByName" align="center" label="创建人" />
      <el-table-column prop="createTime" align="center" label="创建时间" show-overflow-tooltip />

      <el-table-column fixed="right" :label="$t('page.operate')" width="90">
        <template slot-scope="scope">
          <el-button v-if="scope.row.stateDict === '1'" type="text" size="small" @click="modifyClick(scope.row.transferOrderCode)">{{ $t('page.modify') }}</el-button>
        </template>
      </el-table-column>

    </el-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />
    <!-- 缺量调拨 -->
    <el-dialog title="缺量调拨" :visible.sync="UllagedialogVisible" width="30%" :close-on-click-modal="false" center>
      <span>{{ `您选择了 [ ${multipleSelection.length} ] 笔数据进行缺量调拨，请填写原因` }}</span>
      <el-form ref="UllageForm" :model="UllageForm" label-width="120px" :rules="Ullagerules">
        <el-form-item label="缺量调拨原因" prop="reason">
          <el-input v-model="UllageForm.reason" placeholder="输入内容" type="textarea" maxlength="100" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="UllagedialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="UllagesubmitLoading" @click="UllagedialogVisibleSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 撤销调拨 -->
    <el-dialog title="撤销调拨" :visible.sync="CanceldialogVisible" width="30%" :close-on-click-modal="false" center>
      <span>{{ `您选择了 [ ${multipleSelection.length} ] 笔数据，请填写原因` }}</span>
      <el-form ref="CancelForm" :model="CancelForm" label-width="120px" :rules="Cancelrules">
        <el-form-item label="撤销调拨原因" prop="reason">
          <el-input v-model="CancelForm.reason" placeholder="输入内容" type="textarea" maxlength="100" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="CanceldialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="CancelsubmitLoading" @click="CanceldialogVisibleSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转仓库作业提示 -->
    <el-dialog title="提示" :visible.sync="tipVisable" width="600px" :close-on-click-modal="false" center>
      <div style="max-height: 400px; overflow-y: auto;">
        <span>{{ `${tipResult}` }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tipVisable = false">取 消</el-button>
        <el-button type="primary" @click="Tosure">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量导入 -->
    <Upload v-model="uploadDialogVisible" @refresh="queryClick" />

  </div>
</template>
<script>
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { Mixin } from '@/mixin/mixin.js'
import Upload from './components/Upload'
import { usersList, getPhysicsWarehouseList, StockChannelList, transferOrderApi, shortageTransfer, cancelTransfer, exportTrans, exportDetailTrans, transferWarehousePreTip } from '@/api/scm-api'
import { handleBreadTitle, trackExportEvent, trackBtnClickEvent } from '@/utils/monitor'
import { handlePageParams } from '@/utils/index'
import mixin from '@/mixin/scm-mixin'

export default {
  components: {
    Upload, Paging, Select
  },
  mixins: [Mixin, commodityInfoDict, mixin],
  data() {
    return {
      showRow: false,
      tableDatas: [],
      row: '',
      form: {
        transferOrderCodeList: '',
        relationCode: '',
        orderTypeDictList: [],
        createId: '',
        creatTime: [],
        respectTime: [],
        transferTypeDictList: [],
        stateDictList: [],
        outWarehouseCodeList: [],
        inWarehouseCodeList: [],
        inStockChannelIdList: []
      },
      developEngineerFeishuUserId: '',

      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      useroptions: [],
      warehouseOption: [],
      optionsWarehouseConfig: { key: 'id', label: 'warehouseName', value: 'logicWarehouseCode' },
      StockChannelOptions: [],
      multipleSelection: [],
      uploadDialogVisible: false, // 批量导入
      UllagedialogVisible: false, // 缺量调拨
      UllageForm: { reason: '' },
      Ullagerules: { reason: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }},
      UllagesubmitLoading: false,
      CanceldialogVisible: false, // 撤销调拨
      CancelForm: { reason: '' },
      Cancelrules: { reason: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }},
      CancelsubmitLoading: false,
      tipVisable: false, // 转仓库作业提示
      tipResult: [],
      loading: false,
      tableLoading: false,
      exportLoading: false,
      exportDetailsLoading: false
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      return handlePageParams({
        formData: this.form,
        processedParameter: {
          toPretextArray: true,
          timeField: {
            respectTime: ['expectShelfDateStart', 'expectShelfDateEnd'],
            creatTime: ['createTimeStart', 'createTimeEnd']
          },
          preTextArr: { transferOrderCodeList: true, relationCodeList: true }
        }
      })
    }
  },
  watch: {
    UllagedialogVisible(val) {
      if (val) {
         this.$refs.UllageForm?.clearValidate()
        this.$refs.UllageForm?.resetFields()
      }
    },
    CanceldialogVisible(val) {
      if (val) {
         this.$refs.CancelForm?.clearValidate()
        this.$refs.CancelForm?.resetFields()
      }
    },
    'permissonIframe'(val) {
      if (val && val.length) {
        this.$store.dispatch('permission/updataPermissions', val)
      }
    }
  },
  mounted() {
    const defaultForm = this.$store.getters.transferOrderForm || {}
    this.form = Object.assign({}, this.$options.data.call(this).form, defaultForm)
    this._usersList()
    this._queryWarehouseList()
    this._StockChannelList()
    this.queryClick(1)
  },
  created() {
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['expectTransferNumber', 'transferWarehouseNumber', 'notOutLockQty', 'shortageNumber', 'outWarehouseNumber', 'shelfNumber']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },

    showBtn(key) {
      return this.$store.getters.permissions?.includes(key)
    },
    handleDetail(transferOrderCode) {
      this.$router.push({
        name: this.isIframe ? 'IframeDetail' : 'TransferOrderView',
        query: { transferOrderCode }
      })
    },
    handleAdd() {
      this.$router.push({
        name: this.isIframe ? 'IframeTransferOrderAdded' : 'TransferOrderAdded',
        append: true
      })
      trackBtnClickEvent(handleBreadTitle(this.$route) + '新建按钮操作次数')
    },
    handleBatchImport() {
      this.uploadDialogVisible = true
      trackBtnClickEvent(handleBreadTitle(this.$route) + '批量导入按钮操作次数')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 缺量调拨
    handleUllage() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        const UllageSelection = this.multipleSelection.filter(item => item.stateDict !== '4' && item.stateDict !== '6')
        if (UllageSelection.length > 0) {
          return this.$message.warning('仅可选择“部分转仓库作业”、“部分转仓库手工作业”状态操作')
        } else {
          this.UllagedialogVisible = true
        }
      } else {
        return this.$message.warning('请选择数据')
      }
    },
    async UllagedialogVisibleSure() {
      this.$refs['UllageForm'].validate(async valid => {
        if (valid) {
          try {
            this.UllagesubmitLoading = true
            const transferOrderCodeList = []
            this.multipleSelection.map(item => {
              item.transferOrderCode
              transferOrderCodeList.push(item.transferOrderCode)
            })
            const shortParams = Object.assign({}, { transferOrderCodeList }, this.UllageForm)
            const { code } = await shortageTransfer(shortParams)
            if (code === 0) {
              this.$notify({
                message: '操作成功',
                type: 'success'
              })
              this.UllagesubmitLoading = false
              this.UllagedialogVisible = false
              this.queryClick(1)
            }
          } catch (err) { console.log(err) } finally {
            this.UllagesubmitLoading = false
          }
        }
      }
      )
    },
    // 撤消调拨
    handleCancel() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        const CancelSelection = this.multipleSelection.filter(item => item.stateDict !== '1' && item.stateDict !== '2')
        if (CancelSelection.length > 0) {
          return this.$message.warning('仅可选择草稿、已生效状态操作')
        } else {
          this.CanceldialogVisible = true
        }
      } else {
        return this.$message.warning('请选择数据')
      }
    },
    async CanceldialogVisibleSure() {
      this.$refs['CancelForm'].validate(async valid => {
        if (valid) {
          try {
            this.CancelsubmitLoading = true
            const transferOrderCodeList = []
            this.multipleSelection.map(item => {
              item.transferOrderCode
              transferOrderCodeList.push(item.transferOrderCode)
            })
            const cancelParams = Object.assign({}, { transferOrderCodeList }, this.CancelForm)
            const { code } = await cancelTransfer(cancelParams)
            if (code === 0) {
              this.$notify({
                message: '操作成功',
                type: 'success'
              })
              this.CancelsubmitLoading = false
              this.CanceldialogVisible = false
              this.queryClick(1)
            }
          } catch (err) { console.log(err) } finally {
            this.CancelsubmitLoading = false
          }
        }
      }
      )
    },
    // 转仓库作业
    async ToWarehouse() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        const allowedStates = ['4', '2', '6']
        const noWareSelection = this.multipleSelection.filter(item => !allowedStates.includes(item.stateDict))
        if (noWareSelection.length > 0) {
          return this.$message.warning('仅可选择“已生效”、“部分转仓库作业”、“部分转仓库手工作业”状态操作')
        } else {
          const selectTosure = this.multipleSelection.map(item => item.transferOrderCode)
          const { datas } = await transferWarehousePreTip(selectTosure)
          if (datas) {
            this.tipResult = datas
            this.tipVisable = true
          } else {
            this.Tosure()
          }
        }
      } else {
        return this.$message.warning('请选择数据')
      }
    },
    async Tosure() {
      const selectTosure = this.multipleSelection.map(item => item.transferOrderCode)
      this.$router.push({
        name: this.isIframe ? 'IframeToWarehouse' : 'ToWarehouse',
        query: { 'selectTosure': selectTosure }
      })
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    // 获取下拉数据
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _queryWarehouseList() {
      const { datas } = await getPhysicsWarehouseList()
      this.warehouseOption = datas
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },

    // 导出
    async exportConfirm() {
      const params = {}
      const selectTransferOrderCodeList = []
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        this.multipleSelection.map(item => {
          selectTransferOrderCodeList.push(item.transferOrderCode)
          Object.assign(item, { selectTransferOrderCodeList: selectTransferOrderCodeList })
        })
        Object.assign(params, { selectTransferOrderCodeList: selectTransferOrderCodeList })
      } else {
        Object.assign(params, this.queryParameter)
      }
      try {
        this.exportLoading = true
        const { msg, code } = await exportTrans({ params: JSON.stringify(params) })
        if (code === 0) {
          this.$notify({
            message: msg || '操作成功，请前往文件导出界面下载',
            type: 'success'
          })
        }
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route) + '导出')
      } finally {
        this.exportLoading = false
      }
    },
    async exportDetails() {
      // const params = Object.assign({}, this.queryParameter)
      const params = {}
      const selectTransferOrderCodeList = []
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        this.multipleSelection.map(item => {
          selectTransferOrderCodeList.push(item.transferOrderCode)
          Object.assign(item, { selectTransferOrderCodeList: selectTransferOrderCodeList })
        })
        Object.assign(params, { selectTransferOrderCodeList: selectTransferOrderCodeList })
      } else {
        Object.assign(params, this.queryParameter)
      }
      try {
        this.exportDetailsLoading = true
        const { msg, code } = await exportDetailTrans({ params: JSON.stringify(params) })
        if (code === 0) {
          this.$notify({
            message: msg || '操作成功',
            type: 'success'
          })
        } else {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.exportDetailsLoading = false
        trackExportEvent(handleBreadTitle(this.$route) + '导出明细')
      } finally {
        this.exportDetailsLoading = false
      }
    },
    preText(pretext) {
      const arr = []
      const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
      array.map(item => {
        if (item) {
          arr.push(item)
        }
      })
      return arr.join(',')
    },

    // 点击跳转到修改
    modifyClick(row) {
      this.$router.push({
        name: this.isIframe ? 'IframeTransferOrderModify' : 'TransferOrderModify',
        query: { 'transferOrderCode': row, isedit: 1 }
      })
    },
    // 点击查询获取信息
    async queryClick(flag) {
      try {
        this.tableLoading = true
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await transferOrderApi(this.queryParameter, this.pager)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records
        }
        this.loading = false
        this.tableLoading = false
        this.multipleSelection = []
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        this.tableLoading = false
        this.$store.dispatch('saveForm/saveTransferOrder', this.form)
      }
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    }

  }
}
</script>
<style scoped>
/* .logDetail {
  white-space: pre-wrap;
} */
</style>
