import { getAllDict } from '@/api/user'
export const Mixin = {
  data() {
    return {
      salesTypeOpt: [
        {
          label: this.$t('title.SampleOutbound'),
          val: 'sample'
        }, {
          label: this.$t('title.RetailOutbound'),
          val: 'retail'
        }, {
          label: this.$t('title.SpontaneousOutbound'),
          val: 'spontaneous'
        }
      ],
      saleStatusOpt: [
        {
          label: this.$t('title.Drafts'), // 草稿
          val: 0
        }, {
          label: this.$t('page.Inforce'), // 已生效
          val: 1
        }, {
          label: this.$t('title.outofthelibrary'), // 待出库
          val: 2
        }, {
          label: this.$t('title.OutOfDate'), // 已作废
          val: 3
        }, {
          label: this.$t('title.isApprove'), // 已复核
          val: 4
        }, {
          label: this.$t('page.Outofstock'), // 已出库
          val: 5
        }, {
          label: this.$t('title.Stopshipment'), // 终止发货
          val: 6
        }
      ],
      waveStatusOpt: [
        {
          label: this.$t('page.Inforce'),
          val: 0
        }, {
          label: this.$t('title.Partialpicking'),
          val: 1
        }, {
          label: this.$t('title.waitingPick'),
          val: 2
        }, {
          label: this.$t('title.completed'),
          val: 3
        }, {
          label: this.$t('title.OutOfDate'),
          val: 4
        }
      ],
      Dictionaries: {}
    }
  },
  mounted() {
    if (Object.keys(this.Dictionaries).length) return
    if (sessionStorage.getItem('Dictionaries')) {
      this.Dictionaries = JSON.parse(sessionStorage.getItem('Dictionaries') || '[]')
    } else {
      this.getDictionaries()
    }
  },
  computed: {
    outboundStatusOpt() {
      return this.getDictionariesOpt('OUTBOUND_STATUS')
    },
    outboundTypeOpt() {
      return this.getDictionariesOpt('OUT_BOUND_TYPE')
    },
    documentStatusOpt() {
      return this.getDictionariesOpt('SHIPMENT_STATUS')
    },
    inboundTypeOpt() {
      return this.getDictionariesOpt('DEPOSIT_TYPE')
    }

  },
  methods: {
    getStatusLabel(status) {
      for (const v of this.outboundStatusOpt) {
        if (v.val === +status) return v.label
      }
      return status
    },
    getSalesTypeLabel(type) {
      for (const v of this.salesTypeOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getSaleStatusLabel(status) {
      for (const v of this.saleStatusOpt) {
        if (v.val === +status) return v.label
      }
      return status
    },
    getWaveStatusOpt(status) {
      for (const v of this.waveStatusOpt) {
        if (v.val === +status) return v.label
      }
      return status
    },
    getInboundTypeLabel(type) {
      for (const v of this.inboundTypeOpt) {
        if (v.val === type) return v.label
      }
      return type
    },
    getDictionariesOpt(key) {
      if (!key) return []
      const LangObj = {
        'zh': 'zh-CN',
        'en': 'en-US'
      }
      const langKey = LangObj[this.$i18n.locale]
      const Arr = []
      this.Dictionaries[key] && this.Dictionaries[key].map(v => {
        if (v.dictName) Arr.push({ label: JSON.parse(v.dictName)[langKey], val: v.dictValue })
      })
      return Arr
    },
    async getDictionaries() {
      const { datas } = await getAllDict()
      if (Object.keys(datas).length) {
        this.Dictionaries = datas
        sessionStorage.setItem('Dictionaries', JSON.stringify(datas))
      }
    }
  }
}
