<template>
  <div>
    <el-dialog
      title="批量导入"
      :visible.sync="visible"
      width="90%"
      :close-on-click-modal="false"
      @close="closed"
    >
      <el-steps
        :active="active"
        align-center
        class="mb-3"
        finish-status="success"
      >
        <el-step title="上传文件" />
        <el-step title="数据预览" />
        <el-step title="调拨单预览" />
      </el-steps>

      <main class="upload-order-content">
        <section v-show="active === 0" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入数据信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</small>
            <el-button
              class="mt-2"
              type="text"
              :loading="downloadLoding"
              @click="handleTemplate"
            >下载模板</el-button>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的信息表</h3>
            <small>文件后缀名必须为xls
              或xlsx（即Excel格式），文件大小不得大于10M，最多不超过10000条数据</small>
            <ImportFile
              ref="uploadRef"
              :limit="1"
              class="mt-8px"
              :auto-upload="false"
              :upload-file="uploadPlanFile"
              accept=".xlsx, .xls"
              :types="['xls', 'xlsx']"
              :show-file-list="true"
              button-type="text"
              import-name="上传文件"
              @getFile="({ file: val }) => (file = val)"
              @removeFile="handleFileRemove"
            />
          </div>
          <el-divider />

          <div class="download-template1">
            <h3 class="my-2">特别提示</h3>
            <small>导入过程中如发现个别数据校验不通过，则全量回滚修正后再重新操作导入</small>
          </div>
        </section>
        <section v-show="active === 1" class="import-orders">
          <div class="upload-error">
            <div class="upload-error--item">
              <span class="my-2">正常数量条数：</span>
              <el-link
                type="success"
                class="success-account"
                :underline="false"
              >
                {{ tableInfo.errorResult.successAccount || 0 }} 条
              </el-link>
            </div>
            <div class="upload-error--item">
              <span class="my-2">异常数量条数：</span>
              <el-link type="danger" class="mr-2" :underline="false">
                {{ tableInfo.errorResult.errorAccount || 0 }} 条
              </el-link>
              <ElButton
                v-show="tableInfo.errorResult.errorAccount"
                type="text"
                :loading="errorLoading"
                @click="handleExport"
              >下载异常数据详情</ElButton>
            </div>
            <div class="upload-error--item">
              <span class="my-2">预警数量条数：</span>
              <el-link type="warning" class="mr-2" :underline="false">
                {{ tableInfo.errorResult.tipAccount || 0 }} 条
              </el-link>
            </div>
          </div>
          <el-divider />

          <vxe-table
            ref="multipleTable"
            max-height="500px"
            align="center"
            :data="tableInfo.defaultList"
            :cell-style="cellStyle"
            :row-config="{ height: 80 }"
          >
            <vxe-table-column
              field="errorMsgList"
              title="错误信息"
              min-width="140"
            >
              <template #default="{ row }">
                {{ handleErrorMsg(row.errorMsgList, row.tipMsgList) }}
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="transferTypeI18"
              title="调拨类型"
              width="90"
            >
              <template #default="{ row }">
                <span>{{
                  row.transferTypeI18 === "null" ? "" : row.transferTypeI18
                }}</span>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="outWarehouseName"
              title="调出仓库"
              width="90"
            />
            <vxe-table-column
              field="inWarehouseName"
              title="调入仓库"
              width="90"
            />
            <vxe-table-column
              field="outStockChannelName"
              title="调出备货渠道"
              width="90"
            />
            <vxe-table-column
              field="inStockChannelName"
              title="调入备货渠道"
              width="100"
            />
            <vxe-table-column field="skuDisplay" title="Sku" width="100" />
            <vxe-table-column
              field="platSkuDisplay"
              title="PlatSku"
              width="100"
            />
            <vxe-table-column
              field="transferNumberDisplay1"
              title="调拨数量1"
              width="100"
            />
            <vxe-table-column
              field="expectShelfDateDisplay1"
              title="期望上架日期1"
              width="100"
            />
            <vxe-table-column
              field="transferNumberDisplay2"
              title="调拨数量2"
              width="100"
            />
            <vxe-table-column
              field="expectShelfDateDisplay2"
              title="期望上架日期2"
              width="100"
            />
            <vxe-table-column
              field="transferNumberDisplay3"
              title="调拨数量3"
              width="100"
            />
            <vxe-table-column
              field="expectShelfDateDisplay3"
              title="期望上架日期3"
              width="100"
            />
          </vxe-table>
          <Paging
            :page-sizes="[10, 20, 40, 100]"
            :pager="pager"
            end
            @pagination="pagerUpdate"
          />
        </section>
        <section v-show="active === 2" style="text-align: center">
          <vxe-table
            ref="multipleTable"
            :loading="Importloading"
            max-height="500px"
            align="center"
            :data="tableInfo.showList"
            :cell-style="cellStyle"
            :row-config="{ height: 80 }"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column
              field="tipList"
              title="提示信息"
              min-width="140"
            />
            <vxe-column type="seq" title="序号" width="60" />
            <vxe-table-column
              field="transferOrderCode"
              title="调拨单号"
              min-width="140"
            />
            <vxe-table-column
              field="transferTypeI18"
              title="调拨类型"
              min-width="140"
            />
            <vxe-table-column
              field="outStockChannelName"
              title="调出备货渠道"
              width="90"
            />
            <vxe-table-column
              field="inStockChannelName"
              title="调入备货渠道"
              width="90"
            />
            <vxe-table-column
              field="outWarehouseName"
              title="调出仓库"
              width="90"
            />
            <vxe-table-column
              field="inWarehouseName"
              title="调入仓库"
              width="100"
            />
            <vxe-table-column
              field="expectTransferNumber"
              title="预计调拨数量"
              width="100"
            />
            <vxe-table-column
              field="expectShelfDate"
              title="期望上架时间"
              width="100"
            />
            <vxe-table-column
              field="remark"
              :edit-render="{}"
              title="备注"
              width="120"
            >
              <template #edit="{ row }">
                <vxe-input v-model="row.remark" type="text" maxlength="256" />
              </template>
            </vxe-table-column>
          </vxe-table>
        </section>
      </main>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-show="active === 0" @click="closed">取消</el-button>
          <el-button
            v-show="[1, 2].includes(active)"
            :loading="loading"
            @click="handleToReview('previous')"
          >上一步</el-button>
          <el-button
            v-show="active === 0"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
          <el-button
            v-show="active === 1"
            type="primary"
            :loading="loading"
            @click="handleToReview('toTurn')"
          >下一步</el-button>
          <el-button
            v-show="active === 2"
            type="primary"
            :loading="loading"
            @click="handleToReview('success')"
          >提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'
import { importTransView, findVendorDownloadUrl, createTransferOrderView, createTransferOrderSubmit } from '@/api/scm-api'
import { downloads } from '@/utils'
import Paging from '@/components/Pagination'
import dayjs from 'dayjs'
export default {
  components: { ImportFile, Paging },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pager: { size: 20, current: 1, total: 0 },
      multipleTable: [],
      file: '',
      fileList: [],
      active: 0,
      downloadLoding: false,
      loading: false,
      errorLoading: false,
      Importloading: false,
      tableInfo: {
        defaultList: [],
        tableList: [],
        errorResult: {
          successAccount: 0,
          errorAccount: 0,
          tipAccount: 0
        },
        showList: []
      },
      confirmFlag: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
        this.confirmFlag = false
      }
    }
  },
  methods: {
    async handleTemplate() {
      try {
        this.downloadLoding = true
        const { datas } = await findVendorDownloadUrl('TranserOrderImport')
        downloads(datas)
      } finally {
        this.downloadLoding = false
      }
    },
    pagerUpdate(val) {
      Object.assign(this.pager, val)
      const { current, size } = this.pager
      this.tableInfo.defaultList = this.tableInfo.tableList.slice((current - 1) * size, current * size)
    },
    handleFileRemove() {
      this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      // this.file = val
    },
    async uploadPlanFile() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)
        const { code, datas } = await importTransView(formData)
        if (code === 0) {
          this.tableInfo.tableList = datas
          this.pager.current = 1
          const { current, size } = this.pager
          this.pager.total = datas.length
          this.tableInfo.defaultList = datas.slice((current - 1) * size, current * size)
          const { tableList, errorResult } = this.tableInfo
          let tipAccount = 0
          let errorAccount = 0
          let successAccount = 0
          tableList.forEach((item) => {
            if (item.tipMsgList?.length) {
              tipAccount++
            }
            if (item.errorMsgList?.length) {
              errorAccount++
            }
            if (!item.errorMsgList?.length && !item.tipMsgList?.length) {
              successAccount++
            }
          })

          Object.assign(errorResult, {
            successAccount,
            errorAccount,
            tipAccount
          })

          this.file = ''
          this.fileList = []
          this.active++
        }
      } finally {
        this.loading = false
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 0) {
          if (!this.file) return this.$message.warning('请上传文件')
          this.$refs.uploadRef.$refs.uploadForm.submit()
        }
      }
      if (type === 'previous') {
        this.active--
        this.tableInfo.showList = []
        this.confirmFlag = false
      }
      if (type === 'success') {
        this.handleImport()
      }
      if (type === 'toTurn') {
        if (this.tableInfo.tableList.some((item) => (item.errorMsgList))) { return this.$message.warning('当前预览数据存在错误，无法提交') }
        this.active = 2
        this.handleView()
      }
    },
    async handleView() {
      try {
        this.loading = true
        const { tableList } = this.tableInfo
        const { datas } = await createTransferOrderView(tableList)
        this.tableInfo.showList = datas.map(item => Object.assign(item, { remark: '' }))
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    basicSuccess() {
      this.$emit('refresh')
      this.visible = false
      this.tableInfo.showList = []
      this.confirmFlag = false
      return
    },
    handleToWarehouse(toWarehouseList) {
      this.$confirm('调拨单生成成功，是否立即转仓库作业？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async() => {
          this.$router.push({
            path: 'to-warehouse',
            append: 'true',
            query: { 'selectTosure': toWarehouseList }
          })
        }).catch(() => {
          this.$emit('refresh')
          this.visible = false
          this.tableInfo.showList = []
        })
    },
    handleBasicSuccess() {
      this.$emit('refresh')
      this.visible = false
      this.tableInfo.showList = []
    },
    async handleImport() {
      // 第一次提交有错误提示返回，再确认提交一次
      try {
        this.loading = true
        const importParams = Object.assign({}, { transferOrderList: this.tableInfo.showList }, { confirmFlag: this.confirmFlag })
        const { code, datas = [] } = await createTransferOrderSubmit(importParams)
        if (code === 0 && datas?.length) {
          const toWarehouseList = []
          for (const dataItem of datas) {
            const tableItem = this.tableInfo.showList.find(item => item.transferOrderCode === dataItem.transferOrderCode)
            if (tableItem) {
              tableItem.tipList = dataItem.tipList?.join(';')
            }
            if (dataItem.stateDict === '2' && dataItem.transferTypeDict !== '1') {
              toWarehouseList.push(dataItem.transferOrderCode)
            }
          }

          if (this.confirmFlag) {
            // 有错误提示后的第二次确认
            toWarehouseList.length ? this.handleToWarehouse(toWarehouseList) : this.handleBasicSuccess()
          } else if (this.tableInfo.showList.some(v => v.tipList?.length)) {
            this.$forceUpdate()
            this.confirmFlag = true
          } else if (toWarehouseList.length) {
            this.handleToWarehouse(toWarehouseList)
          } else {
            this.handleBasicSuccess()
          }
        }
      } finally {
        this.loading = false
      }
    },
    handleErrorMsg(errorMsgList = [], tipMsgList = []) {
      const messages = errorMsgList?.length ? errorMsgList : tipMsgList
      return messages?.join(';')
    },
    // 下载错误信息表格
    async handleExport() {
      try {
        this.errorLoading = true
        this.tableInfo.tableList = this.tableInfo.tableList.map(item => ({
          ...item,
          transferType: item.transferTypeI18 === 'null' ? '' : item.transferTypeI18,
          errorMsgList: this.handleErrorMsg(item.errorMsgList, item.tipMsgList),
          expectShelfDate1: item.expectShelfDate1 ? dayjs(item.expectShelfDate1).format('YYYY.MM.DD') : '',
          expectShelfDate2: item.expectShelfDate2 ? dayjs(item.expectShelfDate2).format('YYYY.MM.DD') : '',
          expectShelfDate3: item.expectShelfDate3 ? dayjs(item.expectShelfDate3).format('YYYY.MM.DD') : ''
        }))

        import('@/vendor/Export2Excel').then((excel) => {
          const header = ['序号', '错误信息', '调拨类型', '调出仓库', '调入仓库', '调出备货渠道', '调入备货渠道', 'Sku', 'platSku', '调拨数量1', '期望上架日期1', '调拨数量2', '期望上架日期2', '调拨数量3', '期望上架日期3']
          const filterVal = ['no', 'errorMsgList', 'transferType', 'outWarehouseName', 'inWarehouseName', 'outStockChannelName', 'inStockChannelName', 'skuDisplay', 'platSkuDisplay', 'transferNumber1', 'expectShelfDate1', 'transferNumber2', 'expectShelfDate2', 'transferNumber3', 'expectShelfDate3']
          const data = this.formatJson(filterVal, this.tableInfo.tableList)
          excel.export_json_to_excel({
            header,
            data,
            filename: `异常数据列表`
          })
          setTimeout(() => {
            this.errorLoading = false
          }, 1000)
        })
      } catch (err) {
        this.errorLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    cellStyle({ row, column }) {
      if (row.errorMsgList) {
        return {
          color: 'red'
        }
      }
      if (row.tipList || row.tipMsgList?.length) {
        return {
          color: 'orange'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload-dialog /deep/ {
  .el-step__title.is-success,
  .el-step__head.is-success,
  .el-step__description.is-success,
  .el-step__title.is-process,
  .el-step__head.is-process,
  .el-step__description.is-process {
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;

  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-template1 {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(255, 245, 230);
  }

  .upload-error--item {
    display: flex;
    align-items: center;
  }

  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .el-upload--text {
      order: 2;
    }

    .el-upload__tip {
      order: 1;
    }

    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
</style>
